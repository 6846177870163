<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>VENTAS</h4>
        <div>
          <app-checkbox
            id="app_fromLastCR"
            v-model="fromLastCR"
            placeholder="Ultima caja"
          ></app-checkbox>
        </div>
        <app-table-registers ref="tr" :getList="getList">
          <template slot="tl">
            <span></span>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th title="Codigo de Ticket">Codigo</th>
                <th>Fecha</th>
                <th>N° Caja</th>
                <th>Cliente</th>
                <th>Saldo Recibido</th>
                <th>Vuelto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <td>{{ padStart(l.id, 8, "0") }}</td>
                <td>{{ l.created_at }}</td>
                <td>{{ l.cash_register_id }}</td>
                <td>{{ l.client_name }}</td>
                <td>
                  <app-span-money
                    :quantity="l.pay"
                    :moneyCode="l.pay_money_code"
                  ></app-span-money>
                </td>
                <td>
                  <app-span-money
                    :quantity="l.pay_change"
                    :moneyCode="l.pay_money_code"
                  ></app-span-money>
                </td>
                <td>
                  <div class="btn-group">
                    <!-- <a
                    class="btn btn-sm btn-light"
                    href="#"
                    target="_blank"
                    @click.prevent="$refs.dGenerateCE.show()"
                  >
                    <i class="fa fa-print"></i>
                  </a>-->
                    <a
                      class="btn btn-sm btn-light"
                      :href="backendUrl + 'print/store/sales-ticket/' + l.id"
                      target="_blank"
                    >
                      <i class="fa fa-print"></i>
                    </a>
                    <slot :sale="l">
                      <!-- <router-link :to="'/logistic/catalogs/' + l.id" class="btn btn-light btn-sm" title="Editar"><i class="fa fa-edit"></i></router-link> -->
                      <a
                        v-show="l.origin_id == 1"
                        @click="
                          $refs.ddelItem.show();
                          $refs.ddelItem.val = l;
                        "
                        class="btn btn-light btn-sm"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </slot>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-yn
      ref="ddelItem"
      @yes="deleteItem($refs.ddelItem.val.id)"
      @no="$refs.ddelItem.hide()"
    >
      <p v-if="$refs.ddelItem && $refs.ddelItem.val">
        Seguro de eliminar este registro
        <strong>{{ $refs.ddelItem.val.id }}</strong
        >?
      </p>
    </app-modal-yn>

    <app-modal-basic ref="dGenerateCE">
      <h4 class="mb-1">GENERAR COMPROBANTE ELECTRONICO</h4>
      <button class="btn btn-light" @click="go(1, $refs.dGenerateCE.val)">
        BOLETA
      </button>
      <button class="btn btn-light" @click="go(2, $refs.dGenerateCE.val)">
        FACTURA
      </button>
    </app-modal-basic>
  </div>
</template>

<script>
import { padStart } from "lodash";
import { StoreService } from "../StoreService";

export default {
  data: () => ({
    list: {},
    backendUrl: process.env.VUE_APP_API_URL,
    fromLastCR: false
  }),
  created() {
    this.fromLastCR = this.lastCashRegister;
  },
  props: {
    mySales: {
      default: false
    },
    lastCashRegister: {
      default: false
    },
    client_id: {}
  },
  watch: {
    fromLastCR() {
      this.$refs.tr.gl();
    }
  },
  methods: {
    reload() {
      this.$refs.tr.gl();
    },
    padStart() {
      return padStart(arguments[0], arguments[1], arguments[2]);
    },
    deleteItem(id) {
      StoreService.deleteSale(id).then(() => {
        this.$refs.tr.gl();
        this.$refs.ddelItem.hide();
      });
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        StoreService.getSales({
          page,
          search,
          mySales: this.mySales ? 1 : undefined,
          fromMyCashRegister: this.fromLastCR ? 1 : 0,
          client_id: this.client_id
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
