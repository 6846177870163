<template>
  <div>
    <app-checkbox v-model="all" @input="emitValue()">Todos </app-checkbox>
    <app-checkbox
      v-for="(l, i) in reasons"
      :key="i"
      v-model="value[i]"
      @input="
        all = false;
        emitValue();
      "
    >
      {{ l }}
    </app-checkbox>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    value: {},
    all: true
  }),

  computed: {
    ...mapState({
      reasons: s => s.config.service.treatments.history_types
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    emitValue() {
      this.$emit("input", this.getValues());
    },
    getValues() {
      if (this.all) {
        return Object.keys(this.reasons);
      } else {
        let aList = [];
        for (let id of Object.keys(this.value)) {
          if (this.value[id]) {
            aList.push(id);
          }
        }
        return aList;
      }
    }
  }
};
</script>

<style></style>
