<template>
  <div>
    <h4>HISTORIAL DE TRATAMIENTOS</h4>
    <div class="row">
      <div class="col">
        <app-table-registers ref="tr" :getList="getTreatmentHistory">
          <template slot="afterNewBtn">
            <div class="d-inline ml-1">
              <button
                class="btn btn-secondary"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Filtrar <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="dropdown-item">
                  <HistoryTypes
                    ref="elHistoryTypes"
                    @input="$refs.tr.gl()"
                  ></HistoryTypes>
                </div>
              </div>
            </div>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th>FECHA</th>
                <th>TRATAMIENTO</th>
                <th class="text-center">DETALLE</th>
                <th>MONTO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in history" :key="l.id">
                <td>{{ l.created_at }}</td>
                <td>
                  <router-link
                    :to="
                      '/dental-clinic/patients/' +
                        client_id +
                        '/treatment-plans/' +
                        l.treatment_plan_id
                    "
                    >{{ l.treatment_plan_id }}
                    {{ l.treatment_plan_name }}</router-link
                  >
                </td>
                <td>{{ l.observation }}</td>
                <td>{{ l.amount }}</td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
// import { mapState } from "vuex";
import HistoryTypes from "./HistoryTypes";

export default {
  components: {
    HistoryTypes
  },

  // directives
  // filters

  props: {
    client_id: {}
  },

  data: () => ({
    history: {}
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    getTreatmentHistory(params) {
      return new Promise(rsv => {
        DentalClinicService.getTreatmentHistory({
          ...params,
          client_id: this.client_id,
          types: this.$refs.elHistoryTypes.getValues()
        }).then(res => {
          this.history = res.data;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
