<template>
  <div>
    <Sales :client_id="client_id" ref="elSales" v-slot:default="{ sale }">
      <!-- <span>{{sale.id}} </span> -->
      <button
        class="btn btn-light btn-sm"
        v-show="[2, 3].includes(sale.origin_id)"
        @click="
          $refs.dDelete.show();
          $refs.dDelete.val = sale.id;
        "
      >
        <i class="fa fa-trash"></i>
      </button>
    </Sales>

    <History :client_id="client_id"></History>

    <app-modal-yn ref="dDelete" @yes="deleteTreatmentPay($refs.dDelete.val)">
      <p>Anular pago realizado</p>
    </app-modal-yn>
  </div>
</template>

<script>
import Sales from "../../store-module/sales/Index";
import History from "../treatments/History";
import { DentalClinicService } from "../service";

export default {
  components: {
    Sales,
    History
  },
  props: {
    client_id: {}
  },
  data: () => ({
    //
  }),
  methods: {
    deleteTreatmentPay(sale_id) {
      DentalClinicService.deleteSale(sale_id).then(
        () => {
          this.$refs.elSales.reload();
        },
        err => {
          this.$store.dispatch("showError", err.response.data.message);
        }
      );
    }
  }
};
</script>

<style>
</style>
